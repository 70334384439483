import { Checkbox, IconButton, ListItem, ListItemButton, ListItemIcon } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown';

function SingleChoiceQue({ question, getSingleTypeAnswer, loading, questionNo, selectedAnswer }) {
    const [selectedOptions, setSelectedOptions] = useState([]);


    useEffect(() => {
        if (selectedAnswer !== -1) {
            let optionSelection = [...selectedOptions];
            optionSelection[questionNo] = selectedAnswer;
            setSelectedOptions(optionSelection);
        }
    }, [questionNo])


    //Function to handle single type answer and pass data to parent via function call
    const handleToggle = (value, ansId) => {
        let optionSelection = [...selectedOptions];
        optionSelection[questionNo] = value;
        setSelectedOptions(optionSelection);
        getSingleTypeAnswer(value, ansId)
    }

    return (
        <div>
            {question.answers.slice().sort((a, b) => a.text.localeCompare(b.text)).map((opt, i) =>
                <ListItem
                    className='bg-slate-100'
                    key={i}
                    secondaryAction={
                        <IconButton edge="end" aria-label="comments">

                        </IconButton>
                    }
                    disablePadding>
                    <ListItemButton disabled={loading} role={undefined} dense onClick={() => handleToggle(i, opt.id)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={(selectedOptions[questionNo] === i)}
                                tabIndex={-1}
                                inputProps={{ 'aria-labelledby': opt }}
                            />
                        </ListItemIcon>
                        <Markdown className='text-l my-3 font-medium text-left text-gray-600'>{opt.text}</Markdown>
                    </ListItemButton>
                </ListItem>
            )}
        </div>
    )
}

export default SingleChoiceQue
