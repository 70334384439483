import React, { useEffect, useState } from 'react'
import Questionnaire from '../components/Questionnaire/Questionnaire';
import StartTest from '../components/StartTest/StartTest';
import { useNavigate } from 'react-router-dom';
import ResultBox from '../components/Result/ResultBox';
import { useParams } from "react-router-dom";
import { getQuestionnaire, getUser, getCandidateResults,getAssessmentData, getCandidateAudioAnswers } from '../Helpers/AsyncCalls'
import { CircularProgress } from '@mui/material';

const Home = ({assessmentType}) => {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setloading] = useState(true)
  const [user, setUser] = useState();
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [candidateResults, setCandidateResults] = useState();
  const [assessmentDetails,setAssessmentDetails] = useState();
  const [audioAnswersLoading,setAudioAnswersLoading] = useState(true);


  useEffect(()=>{
    if(assessmentType==="audio"){
      setCandidateResults({
        "score": null,
        "countOfTimesMouseOutOfTestWindow": 7,
        "hasCandidateExitedTheFullScreen": false
      })
      if(user?.id && assessmentDetails?.id){
        getCandidateAudioAnswers(user?.id,assessmentDetails?.id).then((res) =>{
          if(res.count > 0){
            navigate('/endtest');
          }
          else{
            setAudioAnswersLoading(false);
          }
        });
      }

    }
  },[assessmentType,user,assessmentDetails])

  useEffect(() => {
    if (Object.keys(params).length !== 2) {
      navigate('/404')
    }

    if (user === undefined) {
      getUser(params.userId).then(data => setUser(data))
    }
    
    if (questionnaireData.length <=0 && isStarted ) {
      try {
        getQuestionnaire(assessmentDetails.id).then((data) => {
          
          setQuestionnaireData(data)})
      } catch (error) {
        navigate('/404')
      }
      setloading(false)

    }

    if (assessmentType!=="audio" && candidateResults === undefined)
    {
      getCandidateResults(params.userId, params.questionnaireId).then((data) => setCandidateResults(data))
    }

    if (user !== undefined && questionnaireData !== undefined && candidateResults !== undefined) {
      setloading(false)
    }

    if(assessmentDetails === undefined){
       getAssessmentData(params.questionnaireId)
       .then((data) => {
          if(data.status === 404){
            navigate('/404')
          }
          setAssessmentDetails(data)
        }
      );
    }
     
  }, [user, questionnaireData, navigate, params, candidateResults,assessmentDetails,isStarted])


  return (
    <div>
    {audioAnswersLoading ? (
      <div className="h-screen flex items-center justify-center">
        <CircularProgress />
      </div>
    ) : candidateResults === undefined ||
      candidateResults === null ||
      candidateResults.score === null ? (
      isStarted ? (
        <Questionnaire
          user={user}
          questionnaireData={questionnaireData}
          setCandidateResults={setCandidateResults}
          assessmentDetails={assessmentDetails}
          assessmentType={assessmentType}
        />
      ) : (
        <StartTest
          user={user}
          setIsStarted={setIsStarted}
          loading={loading}
          assessmentDetails={assessmentDetails}
          assessmentType={assessmentType}
        />
      )
    ) : (
      <ResultBox
        user={user}
        score={candidateResults?.score}
        totalQuestions={assessmentDetails?.numOfQuestions}
      />
    )}
  </div>
  )
}

export default Home
