import React, { useState } from 'react';

function TextFieldQue({ getTextFieldAnswer, selectedOptions, questionNo }) {
    const [text, setText] = useState(selectedOptions[questionNo] === -1 ? '' : selectedOptions[questionNo])
    return (
        <div className='textfield-type'>
            <h2>Type Your Answer</h2>
            <div>
                <div>
                    <textarea
                        value={text}
                        onChange={(event) => {
                            getTextFieldAnswer(event.target.value)
                            setText(event.target.value)
                        }}
                        placeholder="Type your answer here..."
                        rows={6}
                        cols={40}
                        style={{ resize: 'vertical' }}
                    ></textarea>
                </div>
                <div>
                </div>
                <br />
            </div>
        </div>
    );
}

export default TextFieldQue;
