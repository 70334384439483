import { Checkbox, IconButton, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';

function MultipleChoiceQue({ question, getMultipleTypeAnswer, loading, selectedAnswers, questionNo }) {

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleToggle = (value) => {
        const currentIndex = selectedOptions.indexOf(value);
        let newSelectedOptions = [...selectedOptions];

        if (currentIndex === -1) {
            newSelectedOptions.push(value);
        } else {
            newSelectedOptions.splice(currentIndex, 1);
        }
        setSelectedOptions(newSelectedOptions);
        getMultipleTypeAnswer(newSelectedOptions, questionNo);
    };

    useEffect(() => {
        if (Array.isArray(selectedAnswers)) {
            setSelectedOptions(selectedAnswers)
        }
        else {
            setSelectedOptions([])
        }
    }, [selectedAnswers])

    return (
        <div>
            <small>There are multiple answers for this question</small>
            {question.answers.map((opt, i) => (
                <ListItem
                    className='bg-slate-100'
                    key={i}
                    secondaryAction={<IconButton edge='end' aria-label='comments'></IconButton>}
                    disablePadding
                >
                    <ListItemButton disabled={loading} role={undefined} dense onClick={() => handleToggle(i)}>
                        <ListItemIcon>
                            <Checkbox
                                edge='start'
                                checked={Array.isArray(selectedAnswers) ? selectedAnswers.includes(i) : selectedOptions.includes(i)}
                                tabIndex={-1}
                                inputProps={{ 'aria-labelledby': opt }}
                            />
                        </ListItemIcon>
                        <Markdown className='text-l my-3 font-medium text-left text-gray-600'>{opt.text}</Markdown>
                    </ListItemButton>
                </ListItem>
            ))}
        </div>
    );
}

export default MultipleChoiceQue;
